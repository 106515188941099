<script setup lang="ts"></script>

<template>
  <footer class="auth-footer">
    <PoweredBy></PoweredBy>
  </footer>
</template>

<style lang="scss" scoped>
.auth-footer {
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 4rem;
}
</style>

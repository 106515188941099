<script setup lang="ts"></script>

<template>
  <main class="auth-body">
    <slot />
  </main>
</template>

<style lang="scss" scoped>
.auth-body {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: center;
}
</style>

<script setup lang="ts"></script>

<template>
  <header class="auth-header">
    <div class="logo" @click="navigateTo('/welcome')">
      <img src="~/assets/images/logo-auth.png" />
    </div>
  </header>
</template>

<style lang="scss" scoped>
.auth-header {
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: flex-end;
  padding: 40px 0 10px;
}

.logo {
  img {
    width: 200px;
  }
}
</style>
